.newCard {
  margin: 0 !important;
  z-index: 0;
  width: 290px;
  height: 182px;
  border-radius: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.rccs__card--unknown > div {
  background: linear-gradient(25deg, #1b048a, #370046) !important;
}

.cardList {
  background-color: transparent !important;
}
